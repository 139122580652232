import React, { useEffect, useState } from 'react'
import { HashRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom'

const DefaultLayout = React.lazy(() => import('../layout/DefaultLayout'))
const Login = React.lazy(() => import('../views/pages/login/Login'))

const RouterController = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [authed, setAuthed] = useState(false)

  async function makeRequest() {
    await fetch('/api/checkUser', { method: 'GET' })
      .then(async (response) => {
        if (response.status === 200) {
          let vJson = await response.json()
          setAuthed(true)
          localStorage.setItem('username', vJson.username)
          localStorage.setItem('permission', JSON.stringify(vJson.permissoes))
        } else setAuthed(false)
      })
      .catch((error) => setAuthed(false))
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    makeRequest()

    setInterval(() => {
      fetch('/api/checkUser', { method: 'GET' })
        .then(async (response) => {
          if (response.status === 200) {
          } else {
            console.log('caiu erro 1')
            navigate('/login')
          }
        })
        .catch((error) => {
          console.log('caiu erro 2')
          navigate('/login')
        })
        .finally(() => {})
    }, 10000)
  }, [])

  if (loading) return null
  else {
    if (authed) return <DefaultLayout />
    else {
      return <Navigate to="/login" replace />
    }
  }
}

const RouterControllerLogin = () => {
  const [loading, setLoading] = useState(true)
  const [authed, setAuthed] = useState(false)

  async function makeRequest() {
    await fetch('/api/checkUser', { method: 'GET' })
      .then(async (response) => {
        if (response.status === 200) {
          let vJson = await response.json()
          setAuthed(true)
          localStorage.setItem('username', vJson.username)
          localStorage.setItem('permission', JSON.stringify(vJson.permissoes))
        } else setAuthed(false)
      })
      .catch((error) => setAuthed(false))
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    makeRequest()
  }, [])

  if (loading) return null
  else {
    if (authed) return <Navigate to="/" replace />
    else {
      return <Login />
    }
  }
}

export { RouterControllerLogin, RouterController }
